import { PureComponent } from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

class PromotionPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.history.push('/en-int/promotions/welcome-offer/');
  }

  render() {
    return null;
  }
}

export default compose(
  withRouter,
)(PromotionPage);
