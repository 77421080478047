/* eslint-disable import/prefer-default-export */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';

export const GamesCache = new Map();

export const GamesKeys = {
  topGames: 'top-games',
  recommendedGAmes: 'recommended-games',
  popularGames: 'popular-games',
  newGames: 'new-games',
};

export function useGetTopGames() {
  const { player } = usePlayer();
  const [games, setGames] = React.useState();
  const [state, setState] = React.useState('loading');
  const dispatch = useDispatch();

  const partyId = player?.partyId;
  const userLoggedIn = !!partyId;

  useEffect(() => {
    if (userLoggedIn) {
      GamesCache.delete(GamesKeys.topGames);
      setGames(undefined);
      setState('loading');
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (games?.length || state === 'error') return;

    const getData = async () => {
      setState('loading');
      let topGames = GamesCache.get(GamesKeys.topGames);

      try {
        dispatch(setActiveGamesCategory({ topGames: true }));

        if (!topGames) {
          const response = await gamesApi.getRecommendedGames({
            recommendationType: userLoggedIn
              ? 'recommended_for_you'
              : 'new_player_recommendations',
            playerID: userLoggedIn ? partyId : undefined,
          });

          if (!response?.data?.items?.length || response?.data?.items?.length < 5) {
            setState('error');
            return;
          }

          topGames = (() => {
            if (response?.data?.items?.length >= 10) {
              return response?.data?.items?.slice(0, 10);
            }
            if (response?.data?.items?.length >= 5) {
              return response?.data?.items?.slice(0, 5);
            }
            if (response?.data?.items?.length < 5) {
              return [];
            }
          })();
          GamesCache.set(GamesKeys.topGames, topGames);
        }

        setGames(topGames);
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };

    getData();
  }, [partyId, games, state, dispatch, userLoggedIn]);

  useEffect(() => {
    if (state === 'error') {
      dispatch(setActiveGamesCategory({ topGames: false }));
    }
  }, [state, dispatch]);

  return {
    games,
    state,
  };
}
