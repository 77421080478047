import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import {
  cashierModalReducer,
  signUpModalReducer,
  signInModalReducer,
  resendVerificationCodeReducer,
  passwordResetRequestReducer,
  passwordResetReducer,
  transactionReducer,
  formReducer,
  vipApplyReducer,
  emailSentReducer,
  errorModalReducer,
  loyaltyShopModalReducer,
  generalErrorModalReducer,
  gamesReducer,
  providerModalReducer,
} from '../../redux/reducers/index';
import userReducer from '../../redux/reducers/userReducer';
import localesReducer from '../../redux/reducers/localesReducer';
import balanceReducer from '../../redux/reducers/balanceReducer';

export const initialState = {
  cashierModal: { isOpen: false, cashierType: 'deposit', providerType: null },
  signInModal: {
    isOpen: false,
    errorMessage: null,
    modalType: 'SIGN_IN',
    sourcePage: '',
    providerType: '',
    errorStatus: null,
  },
  signUpModal: { isOpen: false },
  resendVerificationCodeModal: { isOpen: false, onlySendCodeView: false },
  passwordResetRequestModal: { isOpen: false },
  passwordResetModal: { isOpen: false, queryParams: null },
  transactionModal: { isOpen: false, parameterInfo: null },
  vipApplyModal: { isOpen: false },
  emailSentModal: { isOpen: false },
  providerModal: { isOpen: false },
  loyaltyShopModal: { isOpen: false, itemCode: '', loyaltyList: [] },
  errorModal: {
    isOpen: false,
    calledFunction: '',
    connectionError: false,
    message: null,
  },
  generalModal: { isOpen: false, data: null },
  generalErrorModal: { isOpen: false, message: null, messageTitle: null },
  gameInfoModal: { isOpen: false, data: null },
  balance: { refresh: false },
  userState: { userLoggedIn: false },
  locales: {
    currentLocale: null,
    availableLocales: [],
  },
  userValidationModal: {
    isOpen: false,
    data: null,
  },
  oldTransactionModal: {
    isOpen: false,
    parameterInfo: null,
  },
  games: {
    isLoading: true,
    isFetching: false,
    error: {},
    categories: { isLoading: true, items: [], count: 0 },
    providers: {
      isLoading: true,
      items: [],
      count: 0,
      notAllowedProviders: [],
    },
    gamesByCategories: {},
    gamesByProviders: {},
    gamesByIds: {},
    filtered: {
      isLoading: true,
      games: [],
    },
    favoriteGames: {
      isLoading: true,
      games: [],
    },
    countryPreview: '',
    searched: false,
    activeGamesCategory: {
      topGames: true,
    },
  },
};

const reducer = combineReducers({
  cashierModal: cashierModalReducer,
  signInModal: signInModalReducer,
  signUpModal: signUpModalReducer,
  locales: localesReducer,
  resendVerificationCodeModal: resendVerificationCodeReducer,
  passwordResetRequestModal: passwordResetRequestReducer,
  passwordResetModal: passwordResetReducer,
  userState: userReducer,
  transactionModal: transactionReducer,
  form: formReducer,
  vipApplyModal: vipApplyReducer,
  emailSentModal: emailSentReducer,
  errorModal: errorModalReducer,
  balance: balanceReducer,
  loyaltyShopModal: loyaltyShopModalReducer,
  generalErrorModal: generalErrorModalReducer,
  games: gamesReducer,
  providerModal: providerModalReducer,
});

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
