import Games from '../../../lib/api/cms/gamesService/Games';
import PlayerApi from '../../../lib/api/omega/playerService/PlayerApi';
import {
  attachFavoriteGamesToCategories,
  buildGameCategoryObject,
  calculateMoreGames,
  favoriteGamesCategory,
  favoriteGamesMap,
  getCategoryById,
  removeDomainWhitelistedGames,
} from './GamesActionHelpers';

const playerApi = new PlayerApi();

export const SET_ERROR = 'SET_ERROR';
export const RESET_GAMES = 'RESET_GAMES';
export const RESET_DISPLAY_COUNT = 'RESET_DISPLAY_COUNT';
export const SET_GAMES_BY_CATEGORY = 'SET_GAMES_BY_CATEGORY';
export const SET_GAMES_BY_PROVIDER = 'SET_GAMES_BY_PROVIDER';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const LOAD_MORE = 'LOAD_MORE';
export const LOAD_MORE_FROM_CMS = 'LOAD_MORE_FROM_CMS';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const UPDATE_GAMES = 'UPDATE_GAMES';
export const SET_SEARCHING = 'SET_SEARCHING';
export const SET_SEARCHED_GAMES = 'SET_SEARCHED_GAMES';
export const CLEAR_SEARCHED_GAMES = 'CLEAR_SEARCHED_GAMES';
export const SET_GAME = 'SET_GAME';
export const SET_FAVORITE_GAMES = 'SET_FAVORITE_GAMES';
export const SET_ACTIVE_GAMES_CATEGORY = 'SET_ACTIVE_GAMES_CATEGORY';

export const FAVORITE_GAMES_CATEGORY_ID = 999999;

const gamesApi = new Games();

export const setError = payload => ({ type: SET_ERROR, payload });

export const resetGames = () => dispatch => dispatch({ type: RESET_GAMES });

export const resetDisplayCount = payload => ({ type: RESET_DISPLAY_COUNT, payload });

export const updateGames = payload => ({ type: UPDATE_GAMES, payload });

export const setActiveGamesCategory = payload => ({ type: SET_ACTIVE_GAMES_CATEGORY, payload });

export const setSearchedGames = (
  games,
  nextUrl,
  categoryName,
  providerId,
  isMobile,
  loadMore,
  totalCount,
  type,
) => (dispatch, getState) => {
  const {
    games: {
      providers,
      filtered,
    },
  } = getState();

  const notAllowedProviderIds = providers.notAllowedProviders
    .map(provider => provider.id);

  const filteredGames = games.filter(game => !notAllowedProviderIds.includes(game.provider.id));

  const previousGames = filtered.games || [];

  const searchedGameCategory = {
    id: 900000,
    slug: '',
    name: '',
    games: loadMore
      ? previousGames.concat(filteredGames)
      : filteredGames,
    count: loadMore
      ? previousGames.length + filteredGames.length
      : filteredGames.length,
    displayGamesCount: loadMore
      ? previousGames.length + filteredGames.length
      : filteredGames.length,
    moreGamesToDisplay: null,
    position: 9999,
    showWinnersList: false,
    loadAllGames: true,
    nextUrl: nextUrl || '',
    categoryName: categoryName || null,
    providerId: providerId || null,
    isMobile,
    totalCount,
    type,
  };

  dispatch({ type: SET_SEARCHED_GAMES, payload: searchedGameCategory });
};

export const clearSearchedGames = () => ({ type: CLEAR_SEARCHED_GAMES });

export const setGame = payload => ({ type: SET_GAME, payload });

export const setGamesByCategory = payload => ({
  type: SET_GAMES_BY_CATEGORY,
  payload,
});

export const setGamesBasedOnProvider = payload => ({
  type: SET_GAMES_BY_PROVIDER,
  payload,
});

export const loadMore = payload => ({
  type: LOAD_MORE,
  payload,
});

export const loadMoreCMS = payload => ({
  type: LOAD_MORE_FROM_CMS,
  payload,
});

export const setIsFetching = payload => ({ type: SET_IS_FETCHING, payload });
export const setSearching = () => ({ type: SET_SEARCHING });

export const loadMoreFromCms = (payload, type) => async (dispatch, getState) => {
  const {
    games: {
      gamesByProviders,
      providers,
    },
    userState: { sessionKey },
  } = getState();

  const id = payload;

  if (id) {
    const provider = gamesByProviders[id];
    if (!provider.id) return;

    const url = provider?.nextUrl;
    const totalCount = provider?.totalCount ?? 9999999;

    const { games, moreGamesToDisplay, loadAllGames } = provider;

    const moreGames = calculateMoreGames(games, false, moreGamesToDisplay, 0);

    const offSet = provider.games.length + moreGames;

    if (url) {
      const gamesResult = type === 'studio'
        ? await gamesApi.getGamesByStudioSlug(
          id,
          providers?.countryPreview,
          offSet,
          url,
        )
        : await gamesApi.getGamesByProvider(
          provider.id,
          providers?.countryPreview,
          offSet,
          url,
        );

      const shortenUrl = gamesResult?.data?.meta?.links?.next
        ?.replace(process.env.REACT_APP_API_BASE_URL, '') || null;

      if (gamesResult?.data?.items?.length) {
        dispatch(setIsFetching(false));
        dispatch(
          loadMoreCMS({
            providerId: id,
            fetchedGames: gamesResult.data.items,
            session: sessionKey,
            totalCount: gamesResult?.data?.meta?.total_count,
            nextUrl: shortenUrl,
          }),
        );

        if (loadAllGames && shortenUrl && provider.count < totalCount) {
          dispatch(loadMoreFromCms(id, type));
        }
      }
    }
  }
};

export const loadMoreSearchFromCms = searchText => async (dispatch, getState) => {
  if (!searchText) return;

  const {
    games: {
      providers: {
        items,
      },
      filtered,
    },
  } = getState();

  const { games, moreGamesToDisplay, count } = filtered;

  const moreGames = calculateMoreGames(games, false, moreGamesToDisplay, 0);

  const offSet = filtered.games.length + moreGames;

  const { categoryName, providerId, isMobile } = filtered;

  const searchedProvider = items
    .find(provider => provider.name?.toLowerCase() === searchText);

  const searchResult = await gamesApi.search(
    !searchedProvider ? searchText : null,
    categoryName,
    providerId,
    offSet,
    searchedProvider?.type ?? null,
  );

  if (count >= searchResult.data?.meta?.total_count) {
    return;
  }

  const nextUrl = searchResult?.data?.meta?.links?.next;

  if (searchResult?.data?.items?.length) {
    dispatch(
      setSearchedGames(
        searchResult?.data?.items,
        nextUrl,
        categoryName,
        providerId,
        isMobile,
        'loadMore',
        searchResult.data?.meta?.total_count,
      ),
    );

    if (nextUrl) {
      dispatch(loadMoreSearchFromCms(searchText));
    }
  }
};

export const setGamesByCategories = (categories, mobile) => async (dispatch, getState) => {
  const {
    games: {
      providers,
      gamesByCategories,
    },
  } = getState();

  if (categories?.length) {
    categories.forEach(async (category) => {
      if (category.id !== FAVORITE_GAMES_CATEGORY_ID) {
        if (!gamesByCategories[category.id]) {
          const gamesResult = await gamesApi.getGamesByCategories(
            category.name_english,
            mobile,
          );

          let gamesArray = gamesResult.data
            .sort((a, b) => b.mega_thumb - a.mega_thumb);

          if (providers.notAllowedProviders.length) {
            const exludedProviders = providers.notAllowedProviders
              .map(provider => provider.id);

            gamesArray = gamesResult.data?.filter(
              game => !exludedProviders.includes(game.provider.id),
            );
          }

          const categoryObj = buildGameCategoryObject(category, gamesArray);
          dispatch(setGamesByCategory(categoryObj));
        }
      }
    });
  }
};

export const setActiveCategory = ({
  category, provider, gameStudio,
}) => async (dispatch, getState) => {
  if (!category?.id && !provider?.id && !gameStudio?.id) return;

  const {
    games: {
      gamesByCategories,
      gamesByProviders,
      providers: { countryPreview, notAllowedProviders },
    },
  } = getState();

  if (category?.id && !gamesByCategories[category.id]?.id) {
    if (category.id !== FAVORITE_GAMES_CATEGORY_ID) {
      const gamesResult = await gamesApi.getGamesByCategories(
        category.name_english,
      );

      let gamesInCategory = gamesResult.data;

      if (notAllowedProviders.length) {
        const restrictedProviderIds = notAllowedProviders.map(
          prov => prov.id,
        );
        gamesInCategory = gamesInCategory.filter(
          game => !restrictedProviderIds.includes(game.provider.id),
        );
      }

      const categoryObj = buildGameCategoryObject(category, gamesInCategory);

      dispatch(setGamesByCategory(categoryObj));
    }
  } else {
    dispatch(resetDisplayCount({ category: gamesByCategories[category?.id] }));
  }
  const currentProvider = Object.values(gamesByProviders)?.find(item => item.slug === (provider?.slug || gameStudio?.slug));

  if ((provider?.id || gameStudio?.id) && !currentProvider?.games?.length) {
    const gamesResult = gameStudio?.id
      ? await gamesApi.getGamesByStudioSlug(gameStudio?.id, countryPreview, 0, false, gameStudio?.isWhiteListed)
      : await gamesApi.getGamesByProvider(provider?.id, countryPreview, 0, false, provider?.isWhiteListed);

    const gamesInProviders = gamesResult.data.items;
    const nextUrl = gamesResult?.data?.meta?.links?.next;

    const providerObj = buildGameCategoryObject(provider || gameStudio, gamesInProviders, nextUrl);

    dispatch(setGamesBasedOnProvider(providerObj));
  }
};

export const setActiveGame = gameSlug => async (dispatch, getState) => {
  if (!gameSlug) return;

  const {
    games: { providers, activeGame },
    userState,
  } = getState();

  if (!providers.items.length) return;

  if (activeGame?.slug === gameSlug) return;

  const emailDomain = userState?.email?.split('@')[1];

  const response = await gamesApi.getGameBySlug(gameSlug);

  if (response?.data?.items?.length) {
    const [currentGame] = response.data.items;

    if (currentGame?.id) {
      const whiteListedDomains = currentGame.provider.domain_whitelist?.map(
        domain => domain?.domain,
      );

      const isAllowedGame = !whiteListedDomains.includes(emailDomain);

      if (isAllowedGame) {
        dispatch(setGame(currentGame));
      } else {
        dispatch(setGame({ status: 'NOT_ALLOWED' }));
      }
    }
  }
};

export const updateFavoriteGames = sessionKey => async (dispatch, getState) => {
  const {
    games: {
      categories,
      providers: { notAllowedProviders },
      favoriteGames,
    },
    locales,
  } = getState();

  if (!sessionKey) return;

  const updatedData = {
    categories,
    favoriteGames,
  };

  if (categories.isLoading) return;

  const favoriteGamesResponse = await playerApi.favoriteGames.getFavoriteGames(sessionKey);

  if (favoriteGamesResponse?.data?.games.length) {
    const gamesReference = favoriteGamesResponse.data.games.map(
      game => game.gameId,
    ).join(',');

    const response = await gamesApi.getFavoriteGamesByReference(gamesReference);
    const successFetch = response?.status === 200;

    if (successFetch) {
      const { items: favoriteGamesArray } = response.data;
      const notAllowedProviderIds = notAllowedProviders
        .map(provider => provider.id);

      const filteredFavoriteGames = removeDomainWhitelistedGames(
        favoriteGamesArray,
        notAllowedProviderIds,
      );

      if (!filteredFavoriteGames.length) {
        const filteredCategories = categories.items
          .filter(category => category.id !== FAVORITE_GAMES_CATEGORY_ID);

        const updatedCategories = {
          ...categories,
          items: filteredCategories,
          count: filteredCategories.length,
        };

        Object.assign(updatedData.categories, updatedCategories);
        Object.assign(updatedData.favoriteGames, { isLoading: false, games: [] });
      }

      if (filteredFavoriteGames.length) {
        const favoriteGamesData = {
          isLoading: false,
          games: filteredFavoriteGames,
        };

        Object.assign(favoriteGames, favoriteGamesData);

        // Attach favoriteGames category to categories
        const favGamesCategory = getCategoryById(
          categories,
          FAVORITE_GAMES_CATEGORY_ID,
        );

        if (!favGamesCategory) {
          // Translate favoriteGames Category
          const translatedFavGamesCategory = {
            ...favoriteGamesCategory,
            slug: favoriteGamesMap[locales.currentLocale].slug,
            name: favoriteGamesMap[locales.currentLocale].name,
          };

          // Attach to categories
          const extendedCategories = attachFavoriteGamesToCategories(
            categories,
            translatedFavGamesCategory,
          );

          const updatedCategories = {
            ...categories,
            items: extendedCategories,
            count: extendedCategories.length,
          };

          Object.assign(updatedData.categories, updatedCategories);
        }
      }
    }
  } else {
    const filtered = categories.items
      .filter(category => category.id !== FAVORITE_GAMES_CATEGORY_ID);

    Object.assign(updatedData, {
      categories: {
        ...categories,
        items: filtered,
        count: filtered.length,
      },
      favoriteGames: {
        isLoading: true,
        games: [],
      },
    });
  }

  dispatch({ type: SET_FAVORITE_GAMES, payload: updatedData });
};
