/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useGetTopGames } from '../../../hooks/games/useGetTopGames';
import { SkeletonNew } from './SkeletonNew';
import GameThumbnailList, { SectionWrapper } from '../GameThumbnailList';
import { providerFilter, searchFilter } from './FilteredGames';
import useQuery from '../../../hooks/useQuery';
import useActiveGames from '../../../hooks/games/useActiveGames';

export const TopGames = () => {
  const { games, state } = useGetTopGames();
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeProvider } = useActiveGames();

  if (state === 'error') {
    return null;
  }

  if (state === 'loading') {
    return (
      <SectionWrapper>
        <h2 className="is-size-3 has-text-weight-bold has-text-primary is-uppercase container has-text-centered">
          Recommended for you
        </h2>
        <div
          id="game-grid"
          className="m-game-grid columns is-multiline is-mobile parent-grid skeleton"
        >
          {new Array(5).fill(true).map((_, i) => (
            <SkeletonNew key={i} />
          ))}
        </div>
      </SectionWrapper>
    );
  }

  const filteredGames = games
    ?.filter(game => searchFilter(game, searchText))
    ?.filter(game => providerFilter(game, activeProvider?.id, 'provider'));

  const filteredCategory = {
    id: 999999,
    slug: 'top-games',
    name: 'Top Games',
    displayGamesCount: 12,
    display_name: 'Recommended Games',
    moreGamesToDisplay: 0,
    position: 0,
    showWinnersList: false,
    isMobile: false,
    loadAllGames: true,
    games: filteredGames?.map(game => ({
      ...game,
      mega_thumb: false,
      vertical_thumbnail: {},
    })),
    count: filteredGames?.length,
    totalCount: filteredGames?.length,
  };

  return (
    <GameThumbnailList
      category={filteredCategory}
      maxLimit={filteredCategory?.displayGamesCount}
    />
  );
};
